import React, { useState } from "react"

import LoginStyle from '../css/Login.module.css'

import logo from '../images/logo-cnc-black.png'
import banner from '../images/banner.jpg'

import { API_DEFAULT } from "../services/settings";

function Login(props) {

    const { setAccessToken, setRefreshToken, setAuth, setShowLoader, setShowAlert, setMessage, setSeverity, setShowUser, closeAlert } = props
    const [user, setUser] = useState("")
    const [password, setPassword] = useState("")

    const [shown, setShown] = useState(false)
    const [tooltip, setTooltip] = useState("Ver contraseña")

    // Función que cambia el icono de mostrar contraseña 
    const switchShown = () => {
        setShown(!shown);
        shown ? setTooltip("Ver contraseña") : setTooltip("Ocultar contraseña");
    }


    async function validateUser() {
        closeAlert(); // Ocultar alerta de usuario incorrecto
        setShowLoader(true); //Activar el loader
        validateSession();
        const res = await fetch(
            `${API_DEFAULT}/usuarios/auth`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                "username": user,
                "password": password
            })
        }
        )

        res.json().then(
            data => {
                setShowLoader(false); //Desactivar el loader
                if (data["access"]) {
                    setAccessToken(data["access"]);
                    localStorage.setItem("accessToken", data["access"])

                    setRefreshToken(data["refresh"]);
                    localStorage.setItem("refreshToken", data["refresh"])

                    setAuth(true)
                    localStorage.setItem("auth", true)

                    const shortUser = user.split('@')
                    setShowUser(shortUser[0])
                    localStorage.setItem("user", shortUser[0])

                    setShowAlert(false)
                } else if (data["detail"]) {
                    if (data["detail"] === 'La combinación de credenciales no tiene una cuenta activa') {
                        setMessage("Usuario y contraseña incorrectos")
                        setSeverity("danger")
                        setTimeout(() => { setShowAlert(true) }, 0)
                    } else if (data["detail"] === 'Ha culminado el periodo de piloto.') {
                        setMessage("Su periodo de prueba ha terminado. Comuníquese con la persona que le proporcionó las credenciales para renovar su licencia.")
                        setSeverity("warning-pilot")
                        setTimeout(() => { setShowAlert(true) }, 0)
                    } else {
                        setMessage("Ha excedido el número de IPs desde las cuales puede acceder a la herramienta")
                        setSeverity("warning")
                        setTimeout(() => { setShowAlert(true) }, 0)
                    }
                }
            }
        )
    }

    function validateSession() {
        if (user === '' || password === '') {
            setMessage("Hay campos sin diligenciar")
            setSeverity("danger")
            setTimeout(() => { setShowAlert(true) }, 0)
        }
    }

    // Iniciar sesión con la tecla ENTER
    function LoginWithEnter(e) {
        var tecla = e.keyCode;
        if (tecla === 13) {
            return validateUser()
        }
    }
    window.onkeydown = LoginWithEnter;

    return (
        <>
            <body className={LoginStyle["login-body"]}>
                <main className={LoginStyle["login-main"]}>
                    <div className={LoginStyle["login-container"]}>
                        <div className={LoginStyle["form-container"]}>
                            <h3 className={LoginStyle["form-titulo"]}>RPD Rating Colombia</h3>
                            <img className={LoginStyle["form-logo"]} src={logo} />
                            <form className={LoginStyle["login-form"]}>
                                <div className={LoginStyle["form-input"]}>
                                    <div className={LoginStyle["content-text"]}>
                                        <ion-icon name="person-outline"></ion-icon>
                                        <input type="text" placeholder="Usuario" id="user" onChange={(e) => setUser(e.target.value)} />
                                    </div>
                                </div>
                                <div className={LoginStyle["form-input"]}>
                                    <div className={LoginStyle["content-text"]}>
                                        <ion-icon name="lock-closed-outline"></ion-icon>
                                        <input type={shown ? 'text' : 'password'} placeholder="Contraseña" id="password" onChange={(e) => setPassword(e.target.value)} value={password} />
                                    </div>
                                    <button className={LoginStyle["btn-eye"]} type="button" onClick={switchShown} title={tooltip}>
                                        {shown ? <ion-icon name="eye-off-outline"></ion-icon> : <ion-icon name="eye-outline"></ion-icon>}
                                    </button>
                                </div>
                                <button className={LoginStyle["btn-login"]} type="button" onClick={() => validateUser()}>INICIAR SESIÓN</button>
                                <div className={LoginStyle["form-line"]}></div>
                                <p className={LoginStyle["copyright"]}>{"Copyright © "}
                                    <a href="https://www.centronacionaldeconsultoria.com/" target="_blank" rel="noreferrer">
                                        Centro Nacional de Consultoría (CNC)
                                    </a> 2022
                                </p>
                            </form>
                        </div>
                        <div className={LoginStyle["banner-container"]}>
                            <img className={LoginStyle["banner-container-image"]} src={banner} />
                        </div>
                    </div>
                </main>
            </body>
        </>
    );
}
export default Login;