import React, { useState, useEffect } from "react";
import { Redirect, Switch, Route, useLocation } from "react-router-dom";

import "../css/alert.css"
import "../css/loader.css"

import Login from "./Login";
import Visor from "./Visor";

function Main(props) {
    const [accessToken, setAccessToken] = useState(localStorage.getItem("accessToken"))
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem("refreshToken"))
    const [auth, setAuth] = useState(localStorage.getItem("auth"))

    const [showLoader, setShowLoader] = useState(false) // Mostrar pantalla de carga

    const [showAlert, setShowAlert] = useState(false) // Mostrar alert cuando el usuario intenta iniciar
    const [message, setMessage] = useState("")
    const [severity, setSeverity] = useState("")

    const [showUser, setShowUser] = useState(localStorage.getItem("user")) // Mostrar nombre de user logeado

    const changeAlert = () => {
        let alert = document.getElementById("alert")
        let btnClose = document.getElementById("btn-close")

        if (severity === "info") {
            alert.classList.remove("alert-warning", "alert-warning-pilot", "alert-danger", "alert-danger-scope", "alert-success")
            alert.classList.add("alert-info")

            btnClose.classList.remove("btn-close-warning", "btn-close-warning-pilot", "btn-close-danger", "btn-close-danger-scope", "btn-close-success")
            btnClose.classList.add("btn-close-info")
        }
        else if (severity === "warning") {
            alert.classList.remove("alert-info", "alert-warning-pilot", "alert-danger", "alert-danger-scope", "alert-success")
            alert.classList.add("alert-warning")

            btnClose.classList.remove("btn-close-info", "btn-close-warning-pilot", "btn-close-danger", "btn-close-danger-scope", "btn-close-success")
            btnClose.classList.add("btn-close-warning")
        }
        else if (severity === "warning-pilot") {
            alert.classList.remove("alert-info", "alert-warning", "alert-danger", "alert-danger-scope", "alert-success")
            alert.classList.add("alert-warning-pilot")

            btnClose.classList.remove("btn-close-info", "btn-close-warning", "btn-close-danger", "btn-close-danger-scope", "btn-close-success")
            btnClose.classList.add("btn-close-warning-pilot")
        }
        else if (severity === "danger") {
            alert.classList.remove("alert-info", "alert-warning", "alert-warning-pilot", "alert-danger-scope", "alert-success")
            alert.classList.add("alert-danger")

            btnClose.classList.remove("btn-close-info", "btn-close-warning", "btn-close-warning-pilot", "btn-close-danger-scope", "btn-close-success")
            btnClose.classList.add("btn-close-danger")
        }
        else if (severity === "danger-scope") {
            alert.classList.remove("alert-info", "alert-warning", "alert-warning-pilot", "alert-danger", "alert-success")
            alert.classList.add("alert-danger-scope")

            btnClose.classList.remove("btn-close-info", "btn-close-warning", "btn-close-warning-pilot", "btn-close-danger", "btn-close-success")
            btnClose.classList.add("btn-close-danger-scope")
        }
        else if (severity === "success") {
            alert.classList.remove("alert-info", "alert-warning", "alert-warning-pilot", "alert-danger", "alert-danger-scope")
            alert.classList.add("alert-success")

            btnClose.classList.remove("btn-close-info", "btn-close-warning", "btn-close-warning-pilot", "btn-close-danger", "btn-close-danger-scope")
            btnClose.classList.add("btn-close-success")
        }
    }

    // Mostrar u ocultar alert de error de usuario
    const closeAlert = () => {
        setShowAlert(false) // Función de cerrar para el botón de alert
    }

    if (showAlert) {
        setTimeout(() => closeAlert(), 8000);
    }
    window.addEventListener("click", () => closeAlert())

    useEffect(() => {
        changeAlert() // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [severity]);

    const location = useLocation();
    const [pathname, setPathname] = useState(location.pathname);

    const [paths, setPaths] = useState(localStorage.getItem("customPaths") ? localStorage.getItem("customPaths").split(",") : ["/inicio-sesion", "/visor-rpd"]);

    useEffect(() => {
        setPathname(location.pathname);
    }, [location]);

    return (
        <>{paths.length > 2 ?
            <Redirect to={{ pathname: auth ? (paths.slice(2).includes(pathname) ? pathname : paths.slice(2)[0]) : paths[0], state: { from: props.location } }} />
            :
            <Redirect to={{ pathname: auth ? paths[1] : paths[0], state: { from: props.location } }} />
        }
            <Switch>
                <Route exact path={paths[0]}>
                    <Login
                        setAccessToken={setAccessToken}
                        setRefreshToken={setRefreshToken}
                        setAuth={setAuth}
                        setShowLoader={setShowLoader}
                        setShowAlert={setShowAlert}
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                        setShowUser={setShowUser}
                        closeAlert={closeAlert}
                    />
                </Route>
                <Route path={paths[1]}>
                    <Visor
                        setAccessToken={setAccessToken}
                        setRefreshToken={setRefreshToken}
                        accessToken={accessToken}
                        refreshToken={refreshToken}
                        setAuth={setAuth}
                        setShowLoader={setShowLoader}
                        setShowAlert={setShowAlert}
                        setMessage={setMessage}
                        setSeverity={setSeverity}
                        showUser={showUser}
                        setShowUser={setShowUser}
                        paths={[paths, setPaths]}
                        pathname={pathname}
                    />
                </Route>
            </Switch>
            <div style={{ display: showLoader ? null : "none" }} className="lds-ring loader" id="loader"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <div className="alert-container">
                <div style={{ display: showAlert ? null : "none" }} className="alert" id="alert">
                    <div className="content-icon-info">
                        {severity === 'info' ? <ion-icon name="alert-circle-outline"></ion-icon>
                            : severity === 'warning' ? <ion-icon name="alert-circle-outline"></ion-icon>
                                : severity === 'warning-pilot' ? <ion-icon name="warning-outline"></ion-icon>
                                    : severity === 'danger' ? <ion-icon name="warning-outline"></ion-icon>
                                        : severity === 'danger-scope' ? <ion-icon name="warning-outline"></ion-icon>
                                            : severity === 'success' ? <ion-icon name="checkmark-circle-outline"></ion-icon>
                                                : null
                        }
                    </div>
                    <span className="alert-text">{message}</span>
                    <div className="content-icon-exit">
                        <button className="btn-close" id="btn-close" onClick={() => closeAlert()}>
                            <ion-icon name="close-outline"></ion-icon>
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Main;