import React from "react";
import { Link } from "react-router-dom";

import VisorStyle from '../../css/Visor.module.css';

export default function MenuOtros(props) {
    const { othersViewers, handleShowOthersViewers, paths, handleButtonClick, activeMenuButton } = props;

    const renderOthersViewers = othersViewers.filter(viewer => !(viewer.label === "Datos 3 Canales Personas" || viewer.label === "Agosto 2024 Reescal Esc 1 Personas" || viewer.label === "Agosto 2024 Reescal Esc 2 Personas" || viewer.label === "Agosto 2024 Reescal Esc 3 Personas")).map((viewer, i) => {
        const condition = (value) => value.split('/')[2] === viewer.label.toLowerCase().split(" ").join("-");
        const index = paths.slice(2).findIndex(condition);
        const isActive = activeMenuButton === viewer.id;

        return (
            <Link key={i}
                to={paths[index + 2]}
                className={`${VisorStyle["btn-month"]}${isActive ? ` ${VisorStyle["active"]}` : ''}`}
                onClick={() => handleButtonClick(viewer.id, index)}>
                <ion-icon name="calendar-clear-outline"></ion-icon>
                <span>{viewer.label.includes('Hogares') ? viewer.label.replace(' Hogares', '') : viewer.label}</span>
            </Link>
        );
    });

    return (
        <>
            <div className={VisorStyle["btn-month"]} onClick={handleShowOthersViewers}>
                <ion-icon name="arrow-back-outline" />
                <span>Regresar</span>
            </div>
            {renderOthersViewers}
        </>
    );
}