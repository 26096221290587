import React from 'react';

import CertificateStyle from '../css/Certificate.module.css'

function Certificate() {

    const hideCertificate = () => {
        const certificate = document.getElementById('certificate')
        certificate.classList.remove(CertificateStyle["hide-certificate"])
        certificate.classList.toggle(CertificateStyle["show-certificate"])
    }

    return (
        <>
            <div className={CertificateStyle["certificate"]} id="certificate">
                <div className={CertificateStyle["layer-hide-menu"]} onClick={() => hideCertificate()}></div>
                <div className={CertificateStyle["certificate-container"]} id="certificate-container">
                    <div className={CertificateStyle["certificate-body"]}>
                        <iframe className={CertificateStyle["certificate-pdf"]} src="https://drive.google.com/file/d/1WAGIBQdkZhybp3Wez1ZR_Bg9H5tBfuKo/preview"></iframe>
                    </div>
                    <div className={CertificateStyle["certificate-button-container"]}>
                        <button className={CertificateStyle["certificate-button-cancel"]} onClick={() => hideCertificate()}>CERRAR</button>
                        <a id="btnDownload" className={CertificateStyle["certificate-button-download"]} href="https://drive.google.com/uc?id=1WAGIBQdkZhybp3Wez1ZR_Bg9H5tBfuKo&export=download">DESCARGAR</a>
                    </div>
                </div>
            </div>
        </>
    );
}

export default Certificate;
