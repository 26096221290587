import React from "react";

import MenuUnMinuto from "./MenuUnMinuto";

import VisorStyle from '../../css/Visor.module.css'
import logo from '../../images/logo-cnc.png';

export default function Menu(props) {

    const { viewersOneMinute, viewersFiveMinutes, othersViewers, paths, changeViewer, location, dataViewers } = props;

    const hideMenu = () => {
        const menu = document.getElementById('menu-container')
        menu.classList.remove(VisorStyle["menu-transition"])

        const modal = document.getElementById('modal-menu')
        setTimeout(function () {
            modal.classList.replace(VisorStyle["show-modal-menu"], VisorStyle["hide-modal-menu"])
        }, 400);
    }

    return (
        <>
            <div className={VisorStyle["modal-menu"]} id="modal-menu">
                <div className={VisorStyle["layer-hide-menu"]} onClick={() => hideMenu()}></div>
                <div className={VisorStyle["menu-container"]} id="menu-container">
                    <div className={VisorStyle["menu-header"]}>
                        <button title="Cerrar Menú" onClick={() => hideMenu()} className={VisorStyle["btn-menu"]}>
                            <ion-icon className={VisorStyle["menu-icon"]} name="menu-outline" />
                        </button>
                        <a href="https://www.centronacionaldeconsultoria.com/" target="_blank">
                            <img className={VisorStyle["menu-logo"]} src={logo} />
                        </a>
                    </div>
                    <MenuUnMinuto
                        viewersOneMinute={viewersOneMinute}
                        viewersFiveMinutes={viewersFiveMinutes}
                        othersViewers={othersViewers}
                        paths={paths}
                        changeViewer={changeViewer}
                        location={location}
                        dataViewers={dataViewers}
                    />
                </div>
            </div>
        </>
    )

}