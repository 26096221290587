import React from "react";
import { Link } from "react-router-dom";

import VisorStyle from '../../css/Visor.module.css';

export default function MenuCincoMinutos(props) {
    const { viewersFiveMinutes, handleShowFiveMinutes, paths, handleButtonClick, activeMenuButton } = props;

    const renderViewersFiveMinutes = viewersFiveMinutes.map((viewer, i) => {
        const condition = (value) => value.split('/')[2] === viewer.label.toLowerCase().split(" ").join("-");
        const index = paths.slice(2).findIndex(condition);
        const isActive = activeMenuButton === viewer.id;

        return (
            <Link key={i}
                to={paths[index + 2]}
                className={`${VisorStyle["btn-month"]}${isActive ? ` ${VisorStyle["active"]}` : ''}`}
                onClick={() => handleButtonClick(viewer.id, index)}>
                <ion-icon name="calendar-clear-outline"></ion-icon>
                <span>{viewer.label}</span>
            </Link>
        );
    });

    return (
        <>
            <div className={VisorStyle["btn-month"]} onClick={handleShowFiveMinutes}>
                <ion-icon name="arrow-back-outline" />
                <span>Regresar</span>
            </div>
            {renderViewersFiveMinutes}
        </>
    );
}